@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9l6mi2');
  src:  url('fonts/icomoon.eot?9l6mi2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9l6mi2') format('truetype'),
    url('fonts/icomoon.woff?9l6mi2') format('woff'),
    url('fonts/icomoon.svg?9l6mi2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-all-others:before {
  content: "\e900";
}
.icon-Arrow-down:before {
  content: "\e901";
}
.icon-arrow-up:before {
  content: "\e902";
}
.icon-RAW:before {
  content: "\e903";
}
.icon-arrow-back:before {
  content: "\e904";
}
.icon-building:before {
  content: "\e905";
}
.icon-business:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
.icon-download:before {
  content: "\e908";
}
.icon-education:before {
  content: "\e909";
}
.icon-health:before {
  content: "\e90a";
}
.icon-hotels:before {
  content: "\e90b";
}
.icon-open-spec:before {
  content: "\e90c";
}
.icon-plus:before {
  content: "\e90d";
}
.icon-remove:before {
  content: "\e90e";
}
.icon-search:before {
  content: "\e90f";
}
.icon-shopping:before {
  content: "\e910";
}
.icon-travel:before {
  content: "\e911";
}
