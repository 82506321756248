/* Global Styles */

button {
    font-weight: 600 !important;
}

.tar {
    text-align: right;
}
.mt20 {
    margin-top: 20px !important;
}

.border-bottom {
    border-bottom: 1px solid #ccc;
    padding-top:8px;
}

.label-main {
	margin-top:8px;
	font-size: 1rem;
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 100;
    color: #515050;
}
.viewall {
	font-size: 12px;
	color: #1fcdba;
	margin-left: 8px;
	font-weight: 600;
}
.full-float-tag {
    background: #1fcdba;
    height: 23px;
    padding: 2px;
    width: 65px;
    text-align: center;
    color: #ffffff;
    border-radius: 12px;
    font-weight: 600;
    font-size: 0.8rem;
}
.products-container,
.market-segments-container {
	-webkit-box-shadow: 0px 0px 8px -4px rgba(0,0,0,0.42);
	-moz-box-shadow: 0px 0px 8px -4px rgba(0,0,0,0.42);
	box-shadow: 0px 0px 8px -4px rgba(0,0,0,0.42);
	padding:8px;
	background: #ffffff;
}
.top-spacer-lg {
	margin-top: 62px;
}
.top-spacer-sm {
	margin-top: 30px;
}
/* Home Styles*/

.full-float-tag.home {
	order: 0;
    flex: 0 1 auto;
    align-self: auto;
    position: absolute;
    margin-top: 51px;
}
.brand-container {
	margin: 8px 0 !important;
	-webkit-box-shadow: 0px 0px 8px -4px rgba(0,0,0,0.42);
	-moz-box-shadow: 0px 0px 8px -4px rgba(0,0,0,0.42);
	box-shadow: 0px 0px 8px -4px rgba(0,0,0,0.42);
	padding:8px;
	background: #ffffff;
}
.col-custom-5 {
	width: 20%;
    padding: 4px;
    border-right: 1px solid #efefef;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
}
.col-custom-5 img {
    width: 50%;
    max-width: 7rem;
}
.col-custom-5.last {
	border-right: 0;
}
.products-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
}
.col-custom-5-products {
    width: 20%;
    padding: 0;
    display: inline-flex;
    flex-direction: inherit;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0px 1%;
    justify-content: end;
    height: 14vw;
}
.col-custom-5-products-name {
    width: 20%;
    padding: 0;
    flex-direction: inherit;
    margin: 10px 1% 20px 1%;
}
.product-home-tag {
    position: relative;
    background: #1fcdba;
    height: 25px;
    width: 50px;
    padding: 3px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #ffffff;
    text-align: center;
}
.product-home-name {
    font-weight: 600;
    font-size: 14px;
}
.product-home-maker {
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #1fcdba !important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #1fcdba !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    max-width: 400px;
}
/*Menu*/
/* .MuiCollapse-wrapperInner {
    width: 100%;
    background: #009282;
    margin-right: 10px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
} */

/* .MuiCollapse-wrapperInner [class*="makeStyles-active"] {
    color: #43cdba;
} */

/* .MuiCollapse-wrapperInner a {
    text-transform: capitalize;
    font-size: 0.1rem !important;
    padding: 4px 0 0 26px;
} */

/* span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1 {
    font-weight: 600;
} */

/*Filter*/
.filter-container {
    margin-left: 27px !important;
    margin-right: 20px !important;
}
.filter-container label, .filter-container legend{
    font-weight: 600;
    margin-bottom: 13px;
    color: #000000;
}



/* .MuiDivider-root {
    margin-top: 8px !important;
} */


/* .MuiDialog-paperWidthSm {
    max-width: 900px !important;
} */

/* Icons*/
.spec-icon{
    height: 18px;
    width: 18px;
    background: #1fcdba;
    color: #ffffff;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 15px;
    position: inherit;
    font-size: 15px;
    cursor: pointer;
}


.segment-icons {
    font-size: 30px !important;
    margin-right: 24px;
    overflow: visible !important;
    cursor: pointer;
}

.segment-icons:hover {
    color: #1fcdba;
}

/* Top Brands */

.brands-block div {
    background: #f8fafb !important;
    border: none;
    box-shadow: none;
}

.brands-block p {
    font-weight: 100;
}

.top-brands-block h4{ 
    font-size: 1rem;
    color: #8e8e8e;
    font-weight: 100;   
}

.brands-block h4 {
    font-size: 1rem;
    color: #8e8e8e;
    font-weight: 100;
}

h4 {
    font-size: 1rem !important;
    color: #8e8e8e !important;
    font-weight: 100 !important;
}
/*Tables*/

[class*="MuiPaper-elevation1"]{
    box-shadow: none !important;
    background: #f8fafb !important;
}

[class*="PrivateTabIndicator-root-"]{
    height: 7px !important;
}

.filter-icon-wrapper span{
    height: 36px;
    width: 32px;
    margin: 0 auto;
    padding: 1px 0px;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary {
    background: #1fcdba;
    color: #ffffff;
    padding: 0
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary svg.MuiSvgIcon-root {
    padding: 5px;
}

table.MuiTable-root.MuiTable-stickyHeader tr:nth-child(odd) {
    background: #ffffff;
}

.icon-download { 
    width: 18px !important;
    height: 18px !important;
    font-size: 13px !important;
    padding: 3px 1px 2px 1px;
}

.white-wrapper .MuiCard-root {
    background: #ffffff !important;
    box-shadow: 0px 0px 8px -4px rgba(0,0,0,0.42) !important;
}

.white-wrapper .MuiTypography-colorTextSecondary {
    color: #1fcdba;
    font-weight: 600;
}

.filter-icon-wrapper .MuiFormControlLabel-label:last-of-type {
    display: none;
}

label.MuiFormControlLabel-root.filter-icon-wrapper {
    margin: 0 18.5px;
}

button.MuiButtonBase-root.MuiButton-containedPrimary {
    font-weight: 600;
}

.MuiInput-underline:before {
    border-bottom: 0 !important;
}
.login-el{
    margin: 0 auto;
}

.login-el .MuiInputLabel-formControl {
    transform: translate(10px, 35px) scale(1);
    color: #9f9f9f;
}

.login-el .MuiFormControlLabel-label {
   font-size: 12px;
   font-weight: 600;
}

.MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.75) !important;
    transform-origin: top left;
}